import React, { Fragment, useState } from "react";
import { Card, Avatar, Breadcrumb, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import profile from '../Assests/Icon/dp.jpg';
import LearningImage from '../Assests/Icon/Learning.png';
import CodeImage from '../Assests/Icon/Code.jpg';
import img from '../Assests/Icon/img.jpg'
import img1 from '../Assests/Icon/img1.jpg';
import vital from '../Assests/Icon/Vitals.png';
import Marquee from "react-fast-marquee";
import '../Components/style.css';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {
  AppBar,
  Typography,
  Container,
  Tabs,
  Box,
  Button,
  Toolbar,
  TextField,
} from "@mui/material";
import {
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  WhatsAppOutlined,
  MailOutlined,
  GithubOutlined,
  SmileOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SimpleImageSlider from "react-simple-image-slider";
import { useFormspark } from "@formspark/use-formspark";

const { Meta } = Card;

const ContactPage = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    fetch(
      "https://script.google.com/macros/s/AKfycbz7a8bDk1oHN2poEWZvdBAbYRPdKZ1wlueug5UiyfvDvhNlBhk7DJp1ev0LyfvLCCAaDQ/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const confirm = () => {
    navigate("/login");
  };
  const projectApp = [{ url: img1 }, { url: img }, { url: vital }];
  const images = [{ url: LearningImage }, { url: CodeImage }];
  const resetField = () => {
    setFormData({
      Name: "",
      Email: "",
      Phone: "",
      Subject: "",
    });
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href =
      "https://drive.google.com/file/d/17BIAudeGcCa-CDHXx2INfsPcDiokagE2/view?usp=sharing";
    link.setAttribute("download", "AakashDeep-Resume.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const FORMSPARK_FORM_ID = "DrHkYHPT6";
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Subject: "",
  });

  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    await submit(formData);
    setFormData({
      Name: "",
      Email: "",
      Phone: "",
      Subject: "",
    });
    await fetch(
      "https://script.google.com/macros/s/AKfycbz7a8bDk1oHN2poEWZvdBAbYRPdKZ1wlueug5UiyfvDvhNlBhk7DJp1ev0LyfvLCCAaDQ/exec",
      {
        method: "POST",
        body: JSON.stringify(formData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Box sx={{ paddingTop: 10 }}>
        <AppBar
          sx={{
            bgcolor: "#13a297",
            color: "white",
            position: "fixed",
            top: 0,
            zIndex: 1000,
          }}
        >
          <Tabs>
            <Toolbar>
              <Button
                onClick={confirm}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Admin
              </Button>
            </Toolbar>
          </Tabs>
        </AppBar>
      </Box>
      <Container>
        <Marquee
          direction="right"
          style={{ color: "Highlight", fontWeight: "bold", marginTop: "10px" }}
        >
          Techniques and tips on finding information for a research paper.
          Includes information on library research, internet research, and
          evaluating sources
        </Marquee>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              style={{
                marginTop: "20px",
                //width: "500px",
                background: "#1dd4f3",
                borderRadius: "15px",
                //float: "right",
              }}
              actions={[
                <a
                  title="Github"
                  href="https://github.com/eraakashdeep/Portfolio.git"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GithubOutlined key="github" />
                </a>,
                <a
                  title="Linkedin"
                  href="https://www.linkedin.com/in/eraakashdeep"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedinOutlined key="linkedin" />
                </a>,
                <a
                  title="Mail"
                  href="https://mail.google.com/mail/u/0/https://mail.google.com/mail/u/0/#inbox?compose=aakashdeep983@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MailOutlined key="mail" />
                </a>,
                <a
                  title="X Twitter"
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2Feraakashdeep"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterOutlined key="twitter" />
                </a>,
                <a
                  title="Instagram"
                  href="https://www.instagram.com/_aakash.deep"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramOutlined key="instagram" />
                </a>,
                <a
                  title="Facebook"
                  href="https://www.facebook.com/aakashdeep9830"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookOutlined key="facebook" />
                </a>,
                <a
                  title="Whatsapp"
                  href="https://web.whatsapp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppOutlined key="whatsapp" />
                </a>,
                <a
                  title="Youtube"
                  href="https://www.youtube.com/@aakashdeep3936"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeOutlined key="youtube" />
                </a>,
              ]}
            >
              <Meta
                avatar={
                  <Avatar size={84} src={profile} icon={<UserOutlined />} />
                }
                title="Aakash Deep"
                description={
                  <div>
                    <p>Destination: Software Engineer</p>
                    <p>Qualification: B.Tech in CSE from KIIT</p>
                    <p>DOB: 27-Dec-1999</p>
                    <p>Email: aakashdeep983@gmail.com</p>
                    <p>Phone: +91 9036968103</p>
                    <p>Address: Bangalore, India</p>
                    <p>
                      Resume:
                      <Button onClick={handleDownload}>
                        <DownloadOutlined title="download" />
                      </Button>
                    </p>
                    <p>
                      Worked Project Link : {""}
                      <a href="https://github.com/eraakashdeep/Worked-Project-Link.git">
                        Link
                      </a>
                    </p>
                  </div>
                }
              />
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              style={{
                marginTop: "20px",
                borderRadius: "15px",
              }}
            >
              <h3>Developed Website / Mobile Application</h3>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <a>E-Commercial App</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a>Vitals Android App</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a>Download App Barcode</a>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div style={{ marginTop: "10px" }}>
                <SimpleImageSlider
                  width={500}
                  height={280}
                  images={projectApp}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
        <Marquee direction="left" style={{ color: "red", fontWeight: "bold" }}>
          Hi&nbsp;
          <SmileOutlined />
          &nbsp;Aakash here, i'm working on new feature website(Please drop your
          request query below to serve !)
        </Marquee>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              style={{
                marginTop: "20px",
              }}
            >
              <h3>Top categories</h3>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <a href="">Assignment</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">Project Work</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">Research Paper</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">Learning</a>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div style={{ marginTop: "10px" }}>
                <SimpleImageSlider
                  width={500}
                  height={362}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              style={{
                marginTop: "20px",
              }}
            >
              <form onSubmit={handleSubmit1}>
                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: "center", color: "Highlight" }}
                >
                  REQUEST QUERY FOR WEB/MOBILE APP
                </Typography>
                <TextField
                  label="Name"
                  name="Name"
                  fullWidth
                  type="text"
                  value={formData.Name}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Email"
                  name="Email"
                  fullWidth
                  type="email"
                  value={formData.Email}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Phone Number"
                  name="Phone"
                  fullWidth
                  type="number"
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    sx: {
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      '& input[type="number"]': {
                        "-moz-appearance": "textfield",
                      },
                    },
                  }}
                  value={formData.Phone}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Subject Query"
                  name="Subject"
                  type="text"
                  multiline
                  fullWidth
                  rows={4}
                  value={formData.Subject}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                />{" "}
                <Stack
                  sx={{ display: "flex", justifyContent: "center" }}
                  spacing={2}
                  direction="row"
                >
                  <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    onClick={resetField}
                  >
                    Clear
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </Button>
                </Stack>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <footer>
        <Container maxWidth="lg">
          <Typography variant="body2" color="textSecondary" textAlign="center">
            &copy; {new Date().getFullYear()} Created by Aakash Deep. All rights
            reserved.
          </Typography>
        </Container>
      </footer>
    </Fragment>
  );
};
export default ContactPage;

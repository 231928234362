import React from "react";
import "../Styles/Footer.css";
import facebook from "../Assests/facebook-logo.png";
import instagram from "../Assests/instagram-logo.png";
import youtube from "../Assests/youtube-logo.png";
import openlogo from "../Assests/open-icon.png";

export default function Footer() {
  return (
    <>
      <div id="footer">
        <div className="footer-upper">
          <div className="general-sec">
            <h2> General</h2>
            <div className="general-links">
              <a
                href="https://www.eraakashdeep.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Official Website{" "}
              </a>
              <img className="open-logo" src={openlogo} alt="openlogo" />
            </div>
          </div>
          <div className="social-media">
            <h2> Social media </h2>
            <div className="social-links">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook-logo" />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="facebook-logo" />
              </a>
              <a
                href="https://www.youtube.com/channel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="facebook-logo" />
              </a>
            </div>
          </div>
        </div>
        <div className="horizontal-line"> </div>
        <div className="footer-lower">
          <p>
            {" "}
            &copy; Aakash Deep {new Date().getFullYear()} | Visit us at{" "}
            <a
              href="https://www.eraakashdeep.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              eraakashdeep.com
            </a>{" "}
            for more details{" "}
          </p>
        </div>
      </div>
    </>
  );
}
